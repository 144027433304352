.page--press {
    .press {
        @include for-tablet-landscape-up {
            margin-bottom: 10rem;
        }

        @include container(
            $gutterDefault: 0,
            $gutterBreakpoints: (
                "for-tablet-landscape-up": 0,
                "for-desktop-up": 0,
            ),
            $maxWidth: $container-width + $gutter-desktop * 2,
            $breakpoint: "for-tablet-landscape-up",
            $breakout: null
        );

        &__inner {
            display: flex;
            flex-direction: column-reverse;

            @include for-tablet-landscape-up {
                flex-direction: row;
            }
        }

        .breadcrumb {
            margin-bottom: 2rem;

            @include for-tablet-landscape-up {
                position: absolute;
                z-index: $z-header + 1;
                bottom: 100%;
                left: $gutter-landscape;
                margin-bottom: 0;
            }

            @include for-desktop-up {
                left: $gutter-desktop;
            }
        }

        &__body,
        &__image {
            width: 100%;
            @include for-tablet-landscape-up {
                width: 50%;
            }
        }

        &__pretitle {
            @include pretitle();
            margin-bottom: 3.6rem;
        }

        &__title {
            @include heading-2();
            margin-bottom: 3.6rem;
        }

        &__text {
            @include body-text(); // default text params

            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }

        &__readmore {
            @include button-yellow($show-hover-border: true);
        }

        &__image {
            img {
                width: 100%;
            }
        }

        &__body {
            position: relative;
            padding: $gutter-mobile * 2 $gutter-mobile;
            @include for-tablet-landscape-up {
                padding: $gutter-landscape;
                padding-bottom: 0;
            }

            @include for-desktop-up {
                padding-top: 6.3rem;

                padding-left: $gutter-desktop;
                padding-right: 13.2rem;
            }
        }
    }
}
