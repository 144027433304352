.featured-content {
    margin-top: 4.6rem;
    margin-bottom: 4.6rem;

    @include for-tablet-landscape-up {
        margin-top: 7.6rem;
        margin-bottom: 13.7rem;
    }

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__title {
        @include heading-2($color: black);
        text-align: center;
        margin-bottom: 2.4rem;

        @include for-tablet-landscape-up {
            margin-bottom: 8rem;
        }
    }

    &__list {
        display: grid;
        grid-template: auto / 1fr;
        grid-gap: 4.6rem 3.2rem;

        @include for-tablet-landscape-up {
            grid-template: auto / repeat(2, 1fr);
        }

        .content-card {
            &__image {
                margin-bottom: 3rem;

                img {
                    width: 100%;
                }
            }

            &__title {
                @include heading-base();
                font-size: 2rem;
                line-height: 1.5;
                color: black;
                font-weight: $font-weight-bold;
                margin-bottom: 0.5rem;

                @include for-desktop-up {
                    width: percentage(1/3);
                    margin-right: percentage(2/3);
                    margin-bottom: 0;
                }
            }

            &__text {
                @include body-text($color: $color-gray-3, $font-size: 1.2rem, $line-height: 1.667);
                margin-bottom: 2rem;
                @include for-desktop-up {
                    margin-left: percentage(1/3);
                    width: percentage(2/3);
                }
            }

            &__readmore {
                @include button-yellow($show-hover-border: true);
                @include for-desktop-up {
                    margin-left: percentage(1/3);
                }
            }
        }
    }
}
