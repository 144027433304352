// Overrides for cookie styling
#cconsent-bar,
#cconsent-modal {
    font-family: $font-roboto !important;
    font-size: 1.4rem !important;
    color: $color-gray-3 !important;

    button {
        font-weight: $font-weight-bold;
        font-size: 1.4rem !important;
        line-height: 1.14 !important;
        padding: 1.3rem 1.7rem !important;
        border-radius: 0.5rem !important;
        border: 1px solid transparent !important;
        transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
            background-color 0.2s ease-in-out !important;
        color: black !important;
        background-color: $color-yellow !important;

        &:hover {
            background-color: transparent !important;
            border-color: $color-yellow !important;
        }

        &.consent-give {
        }
    }
}

// Modal

#cconsent-modal {
    &.ccm--visible {
        backdrop-filter: blur(10px);
    }
    .ccm__content {
        background-color: white !important;
        box-shadow: none;
        // box-shadow: 0 0.3rem 0.6rem 0px rgba(0, 0, 0, 0.16);

        // @include for-tablet-landscape-up {
        //     border-radius: 1rem;
        // }

        > .ccm__content__heading {
            background-color: white !important;
            h2 {
                display: block !important;
                font-family: $font-gordita !important;
                font-weight: $font-weight-bold !important;
                font-size: 1.8rem !important;
                line-height: 1.44 !important;
                // @include letter-spacing(-20);
                margin-bottom: 1.2rem !important;
            }

            p {
                font-size: 1.4rem !important;
                line-height: 1.57 !important;
                // @include letter-spacing(10);
            }

            .ccm__cheading__close {
                color: $color-gray-3 !important;
            }
        }

        > .ccm__content__body {
            .ccm__tab-head {
                font-family: $font-gordita !important;
                font-weight: $font-weight-bold !important;
                font-size: 1.6rem !important;

                &__icon-wedge {
                    transition: none !important;
                    // transform-origin: center !important;
                    // transform: translateY(-50%) rotate(0deg) !important;
                }
            }
            .ccm__tabgroup {
                // .ccm__tab-head::before {
                //     color: $color-red !important;
                // }
                // &.checked-5jhk {
                //     .ccm__tab-head::before {
                //         color: $color-green !important;
                //     }
                // }
            }
            .ccm__tab-content {
                p {
                    color: inherit !important;
                    font-size: 1.4rem !important;
                    line-height: 1.57 !important;
                    // @include letter-spacing(10);
                }

                .ccm__list {
                    &__title {
                        margin-bottom: 1.2rem !important;
                    }

                    ul {
                        margin: 0.5rem 0 !important;
                        padding-left: 0 !important;
                        li {
                            padding-left: 1em;
                            position: relative;
                            &::before {
                                content: "-";
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .ccm__footer {
        background-color: white !important;
    }
}
// Bar
#cconsent-bar {
    border-top: 1px solid $color-gray-1;

    .ccb__wrapper {
        .ccb__left {
            margin: 0 !important;
        }

        .cc-text {
            font-size: 1.6rem !important;
            line-height: 1.5 !important;
            color: inherit !important;
            // @include letter-spacing(10);
        }

        .ccb__edit {
            font-size: 1.6rem !important;
        }

        a {
            text-decoration-color: currentColor !important;

            &:hover {
                text-decoration-color: transparent !important;
            }

            // @include ie-hover-underline($invert: false);
        }
    }
}
