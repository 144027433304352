.page--pages {
    .legal-page {
        margin-bottom: 4.6rem;

        @include for-tablet-landscape-up {
            margin-bottom: 10rem;
        }

        @include container(
            $gutterDefault: $gutter-mobile,
            $gutterBreakpoints: (
                "for-tablet-landscape-up": $gutter-landscape,
                "for-desktop-up": $gutter-desktop,
            ),
            $maxWidth: $container-width,
            $breakpoint: "for-tablet-landscape-up",
            $breakout: null
        );

        .breadcrumb {
            margin-bottom: 4.6rem;
        }

        &__pretitle {
            @include pretitle();
            margin-bottom: 2.5rem;
        }

        &__title {
            @include heading-1($color: black);
            margin-bottom: $gutter-mobile;

            @include for-tablet-landscape-up {
                margin-bottom: 6.2rem;
            }

            br {
                display: none;
            }

            @include for-tablet-portrait-up {
                br {
                    display: inline;
                }
            }
        }

        &__text {
            @include body-text();
            max-width: 76.8rem;
        }
    }
}
