// Thin

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Thin.woff2") format("woff2"),
        url("/web/fonts/Gordita-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-ThinItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

// Light
@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Light.woff2") format("woff2"),
        url("/web/fonts/Gordita-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-LightItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// Regular
@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Regular.woff2") format("woff2"),
        url("/web/fonts/Gordita-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-RegularItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

// Medium
@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Medium.woff2") format("woff2"),
        url("/web/fonts/Gordita-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-MediumItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// Bold
@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Bold.woff2") format("woff2"),
        url("/web/fonts/Gordita-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-BoldItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-BoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-Black.woff2") format("woff2"),
        url("/web/fonts/Gordita-Black.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gordita";
    src: url("/web/fonts/Gordita-BlackItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-BlackItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

// 
@font-face {
    font-family: "Gordita Ultra";
    src: url("/web/fonts/Gordita-UltraItalic.woff2") format("woff2"),
        url("/web/fonts/Gordita-UltraItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gordita Ultra";
    src: url("/web/fonts/Gordita-Ultra.woff2") format("woff2"),
        url("/web/fonts/Gordita-Ultra.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
