.quote {
    margin-top: 4.6rem;
    margin-bottom: 4.6rem;

    @include for-tablet-landscape-up {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__inner {
        display: flex;
        flex-direction: column-reverse;
        @include for-tablet-landscape-up {
            flex-direction: row;
        }
    }

    &__text {
        @include for-tablet-landscape-up {
            width: 55%;
        }
        q {
            display: block;
            font-family: $font-gordita;
            font-weight: $font-weight-bold;
            font-size: 2rem;
            line-height: 1.5;
            color: black;
            margin-bottom: 2rem;
            @include for-tablet-landscape-up {
                margin-bottom: 5rem;
            }

            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101.1' height='75' viewBox='0 0 101.1 75' fill='%23ffdc00'%3E%3Cpath d='M80.7,75c8.7-.9,20.1-12.6,16.8-25.5-6-2.1-15.3-6.3-19.8-9.9C75.6,24.3,85.8,15,101.1,10.2L96.3,0C67.8,5.4,54.6,27.6,54.6,47.4,54.6,64.5,66.6,73.8,80.7,75ZM26.1,75c8.7-.9,20.1-12.6,16.8-25.5-6-2.1-15.3-6.3-19.8-9.9C21,24.3,31.2,15,46.5,10.2L41.7,0C13.2,5.4,0,27.6,0,47.4,0,64.5,12,73.8,26.1,75Z' /%3E%3C/svg%3E");
                display: block;
                margin-bottom: 4rem;
                @include for-tablet-landscape-up {
                    margin-bottom: 8rem;
                }
            }

            &::after {
                content: none;
            }
        }

        span {
            font-size: 1.2rem;

            strong {
                color: black;
            }
        }
    }

    &__image {
        width: 100%;
        margin-bottom: 7rem;

        @include for-tablet-landscape-up {
            width: 45%;
            margin-left: 11rem;
            padding-right: 11rem;
        }

        img {
            overflow: hidden;
            border-radius: 1rem;
            width: 100%;

            filter: drop-shadow(3rem 3rem 3rem $color-yellow);
            @include for-tablet-landscape-up {
                filter: drop-shadow(5rem 5rem 4rem $color-yellow);
            }
        }
    }
}
