.image-text {
    @include for-tablet-landscape-up {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    @include container(
        $gutterDefault: 0,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": 0,
            "for-desktop-up": 0,
        ),
        $maxWidth: $container-width + $gutter-desktop * 2,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__inner {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-landscape-up {
            flex-direction: row-reverse;
        }
    }

    &__body,
    &__image {
        width: 100%;
        @include for-tablet-landscape-up {
            width: 50%;
        }
    }

    &__pretitle {
        @include pretitle();
        margin-bottom: 3.6rem;
    }

    &__title {
        @include heading-2();
        margin-bottom: 3.6rem;
    }

    &__text {
        @include body-text(); // default text params

        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    &__readmore {
        @include button-yellow($show-hover-border: true);
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__body {
        padding: $gutter-mobile * 2 $gutter-mobile;
        @include for-tablet-landscape-up {
            padding: $gutter-landscape;
            padding-bottom: 0;
        }

        @include for-desktop-up {
            padding-left: 13.2rem;
            padding-top: 6.3rem;
            // padding-bottom: 6.3rem;
            padding-right: $gutter-desktop;
        }
    }

    &--last {
        margin-bottom: 0;

        .image-text {
            &__body {
                @include for-tablet-landscape-up {
                    padding-bottom: $gutter-landscape;
                }

                @include for-desktop-up {
                    padding-bottom: 6.3rem;
                }
            }
        }
    }
}
