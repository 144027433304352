.breadcrumb {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: $font-gordita;
    font-weight: $font-weight-regular;
    font-size: 1rem;
    color: black;

    &__item {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            &::before {
                content: "\00a0\00a0|\00a0\00a0";
            }
        }

        a,
        span {
            display: flex;

            svg {
                transform: translateY(-0.2rem);
            }
        }

        a {
            &:hover {
                text-decoration-color: currentColor;
            }
        }
    }
}
