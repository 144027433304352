.page--lemonades {
    .drink {
        @include for-tablet-landscape-up {
            margin-top: 5rem;
            margin-bottom: 10rem;
        }

        @include container(
            $gutterDefault: 0,
            $gutterBreakpoints: (
                "for-tablet-landscape-up": 0,
                "for-desktop-up": 0,
            ),
            $maxWidth: $container-width + $gutter-desktop * 2,
            $breakpoint: "for-tablet-landscape-up",
            $breakout: null
        );

        &__inner {
            display: flex;
            flex-direction: column-reverse;
            @include for-tablet-landscape-up {
                flex-direction: row;
            }
        }

        &__body,
        &__image {
            width: 100%;

            @include for-tablet-landscape-up {
                width: 50%;
            }
        }

        &__body {
            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-landscape-up {
                padding: $gutter-landscape;
            }

            @include for-desktop-up {
                padding-top: 6.3rem;
                // padding-bottom: 6.3rem;
                padding-right: 13.2rem;
                padding-left: $gutter-desktop;
            }
        }

        &__pretitle {
            @include pretitle();
            margin-bottom: 3.6rem;
        }

        &__title {
            @include heading-2();
            margin-bottom: 3.6rem;
        }

        &__text {
            @include body-text();
            margin-bottom: 6.4rem;
        }

        &__info {
            margin-bottom: 3.8rem;

            .accordion {
                &:not(:first-child) {
                    padding-top: 2rem;
                }

                padding-bottom: 2rem;
                border-bottom: 1px solid black;

                &__title {
                    @include heading-base();
                    display: flex;
                    justify-content: space-between;
                    font-weight: $font-weight-bold;
                    color: black;
                    cursor: pointer;
                    // Make clickable area bigger (dirty)
                    margin-top: -2rem;
                    margin-bottom: -2rem;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                    // End make clickable area bigger
                    position: relative;

                    &::after {
                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.5' height='8.07' viewBox='0 0 12.5 8.07' fill='%23000'%3E%3Cpath d='M5.59,7.79.28,2.48a.92.92,0,0,1,0-1.32L1.16.28a.92.92,0,0,1,1.32,0L6.25,4,10,.28a.94.94,0,0,1,1.33,0l.88.88a.92.92,0,0,1,0,1.32L6.91,7.79a.92.92,0,0,1-1.32,0Z'/%3E%3C/svg%3E");
                        display: block;
                        transition: transform 0.2s ease-in-out;
                        transform: rotate(180deg);
                    }
                }

                &__text {
                    @include body-text($font-size: 1.2rem, $line-height: 1.667);

                    &-wrapper {
                        padding-top: 2rem;
                    }
                }

                &.closed {
                    .accordion {
                        &__text {
                            overflow: hidden;
                            height: 0;
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }

                &.closed,
                &.closing {
                    .accordion {
                        &__title {
                            &::after {
                                transform: rotate(0);
                            }
                        }
                    }
                }
            }
        }

        &__readmore {
            @include button-yellow($show-hover-border: true);
        }
        &__image {
            picture {
                // @include for-tablet-landscape-up {
                //     width: calc(100% + #{$gutter-landscape});
                // }

                // @include for-desktop-up {
                //     width: calc(100% + #{$gutter-desktop});
                // }
            }
            img {
                width: 100%;
            }
        }
    }
}
