.footer-top,
.footer-bottom {
    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );
}

.footer-top {
    background-color: $color-green;
    padding-top: 4.6rem;
    padding-bottom: 4.6rem + 10rem;

    @include for-tablet-landscape-up {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem + 10rem;
    }

    &__inner {
        @include for-tablet-landscape-up {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__pretitle {
        @include pretitle($color: white);
        margin-bottom: 2.5rem;

        @include for-tablet-landscape-up {
            width: 100%;
        }
    }

    &__title {
        @include heading-base();
        color: white;
        font-size: (4rem / 1.25);
        line-height: 1.25;
        margin-bottom: 1rem;
        
        @include for-tablet-landscape-up {
            width: 55%;
            font-size: 4rem;
            margin-bottom: 0;
        }
    }

    &__text {
        @include body-text($color: white);

        @include for-tablet-landscape-up {
            align-self: flex-end;
            padding-left: 8rem;
            width: 45%;
        }

        @include for-desktop-up {
            width: calc(45% + 6rem);
            margin-right: -6rem;
        }
    }

    &__readmore {
        @include button-yellow();
        margin-top: 2.6rem;
    }
}

.footer-bottom {
    // min-height: 13.4rem;

    &__inner {
        margin-top: -10rem;
        background-color: white;
        padding: 3.9rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .logo {
        color: black;
        display: inline-block;
        margin-bottom: 3.8rem;

        svg {
            width: 15rem;
            height: auto;
        }
    }

    .social {
        font-size: 0;
        margin-bottom: 2.6rem;

        &__item {
            display: inline-flex;

            &:not(:first-child) {
                margin-left: 1.2rem;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.4rem;
                height: 2.4rem;
                color: black;

                &:hover {
                    color: $color-yellow;
                }
            }
        }
    }

    .legal {
        line-height: 1.875;

        a {
            &:hover {
                text-decoration-color: currentColor;
            }
        }
    }
}
