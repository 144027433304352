// Colors
$color-yellow: #FFDC00;
$color-green: #143925;
$color-red: #852219;

$color-gray-1: #D6D6D6;
$color-gray-2: #979797;
$color-gray-3: #858585;

// Fonts
$font-roboto: "Roboto", sans-serif;
$font-gordita: "Gordita", sans-serif;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 700;
$font-weight-ultra: 900;

// Container/gutters
$container-width: 114.2rem;
$gutter-mobile: 2rem;
$gutter-landscape: 6rem;
$gutter-desktop: 15rem;

// Form
$form-gutter-x: 1rem;
$form-gutter-y: 1.5rem;

// Header
$header-height-mobile: 9rem;
$header-height-landscape: 15rem;
$header-height-desktop: 20rem;

// Z-index
$z-header: 100;