@import "~@keplerstein/keplerstein-component-library/scss/base";
@import "~@keplerstein/keplerstein-component-library/scss/container";

// Global //
@import "vars";
@import "fonts";
@import "common";

// Mixins
@import "_mixins/typo";
@import "_mixins/buttons";

// Components
@import "_components/breadcrumb";
@import "_components/header";
@import "_components/footer";
@import "_components/form";
@import "_components/cookies";

// Blocks
@import "_blocks/hero";
@import "_blocks/featured-content";
@import "_blocks/dealers";
@import "_blocks/quote";
@import "_blocks/image-text";
@import "_blocks/text-image";
@import "_blocks/usps";

// Page specific
@import "_singles/contact";
@import "_singles/press";
@import "_singles/dealers";
@import "_lemonades/entry";
@import "_pages/legal-page";
@import "_errors/404";

// Rellax.js

.visual {
    @include for-desktop-up {
        position: absolute;
        z-index: $z-header - 1;
        background-repeat: no-repeat;

        &--lemon-1 {
            width: 39.84px;
            height: 39.84px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.84' height='39.84' viewBox='0 0 39.84 39.84' fill='%23ffdc00'%3E%3Cpath d='M34,5.83A19.93,19.93,0,1,0,34,34,19.95,19.95,0,0,0,34,5.83ZM32.65,32.65a18,18,0,1,1,0-25.46A18,18,0,0,1,32.65,32.65Z' /%3E%3Cpath d='M15.26,20.81a1,1,0,0,0,0-1.78l-8.5-3.52A1.93,1.93,0,0,0,4.13,17a16.09,16.09,0,0,0,0,5.95,1.93,1.93,0,0,0,2.63,1.43Z' /%3E%3Cpath d='M7.49,13.73,16,17.26A1,1,0,0,0,17.26,16L13.73,7.49a1.93,1.93,0,0,0-2.87-.85,16.08,16.08,0,0,0-2.3,1.91,15.9,15.9,0,0,0-1.92,2.31A1.93,1.93,0,0,0,7.49,13.73Z' /%3E%3Cpath d='M19,15.26a1,1,0,0,0,1.78,0l3.53-8.51a1.94,1.94,0,0,0-1.43-2.63,16.19,16.19,0,0,0-6,0,1.93,1.93,0,0,0-1.43,2.63Z' /%3E%3Cpath d='M23.84,17.26l8.51-3.53a1.92,1.92,0,0,0,.85-2.87A15.88,15.88,0,0,0,29,6.64a1.93,1.93,0,0,0-2.87.85L22.59,16A1,1,0,0,0,23.84,17.26Z' /%3E%3Cpath d='M35.71,16.94a1.92,1.92,0,0,0-2.63-1.43L24.58,19a1,1,0,0,0,0,1.78l8.5,3.52a1.92,1.92,0,0,0,2.63-1.43,16,16,0,0,0,0-6Z' /%3E%3Cpath d='M32.35,26.11l-8.51-3.52a1,1,0,0,0-1.25,1.25l3.52,8.51A1.92,1.92,0,0,0,29,33.2,15.88,15.88,0,0,0,33.2,29,1.92,1.92,0,0,0,32.35,26.11Z' /%3E%3Cpath d='M20.81,24.58a1,1,0,0,0-1.78,0l-3.52,8.51a1.92,1.92,0,0,0,1.43,2.63,16.14,16.14,0,0,0,6,0,1.93,1.93,0,0,0,1.43-2.63Z' /%3E%3Cpath d='M16,22.59,7.49,26.11A1.93,1.93,0,0,0,6.64,29a15.88,15.88,0,0,0,4.22,4.22,1.93,1.93,0,0,0,2.87-.84l3.53-8.52A1,1,0,0,0,16,22.59Z' /%3E%3C/svg%3E");

            top: 75rem + 6.2rem; // Hero height desktop + offset
            right: 3.6rem;

            @media (min-width: rem-to-px($container-width)) {
                right: calc(50vw - #{$container-width / 2} + 3.6rem);
            }
        }

        &--lemon-2 {
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='%23ffdc00'%3E%3Cpath d='M28.46,6.25a4,4,0,0,0-.73-4,4,4,0,0,0-4-.73c-5.45-3-13.08-1.6-18.44,3.77s-6.73,13-3.76,18.43a4,4,0,0,0,.73,4,4,4,0,0,0,4,.73c5.46,3,13.08,1.6,18.45-3.76S31.44,11.71,28.46,6.25ZM16.78,4.37a13.31,13.31,0,0,0-8.41,4,13.85,13.85,0,0,0-3.66,6.29.72.72,0,0,1-.84.52h0a.72.72,0,0,1-.56-.9,15.37,15.37,0,0,1,4-6.94,14.76,14.76,0,0,1,9.34-4.43.73.73,0,0,1,.78.72A.74.74,0,0,1,16.78,4.37Z' /%3E%3C/svg%3E");

            top: 150rem;
            left: calc(50% - 15px); // center horizontally
        }

        &--orange-1 {
            width: 39.84px;
            height: 39.84px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.84' height='39.84' viewBox='0 0 39.84 39.84' fill='%23eb6608'%3E%3Cpath d='M19.92,0A19.92,19.92,0,1,0,39.84,19.92,19.92,19.92,0,0,0,19.92,0Zm0,37.92a18,18,0,1,1,18-18A18,18,0,0,1,19.92,37.92Z' /%3E%3Cpath d='M19,34A14.15,14.15,0,0,1,5.8,20.82a1,1,0,0,0-1-.9h0a1,1,0,0,0-1,1,16.09,16.09,0,0,0,15,15,1,1,0,0,0,1-1A1,1,0,0,0,19,34Z' /%3E%3Cpolygon points='20.43 4.21 18.75 5.87 15.92 5.95 17.58 7.45 16.62 9.34 19.32 8.6 21.57 9.68 21.58 7.73 23.92 6.51 21.23 6.04 20.43 4.21' /%3E%3C/svg%3E");
            top: 215rem;
            left: 18.5rem;

            @media (min-width: rem-to-px($container-width)) {
                left: calc(50vw - #{$container-width / 2} + 18.5rem);
            }
        }

        &--orange-2 {
            width: 39.84px;
            height: 39.84px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.84' height='39.84' viewBox='0 0 39.84 39.84' fill='%23eb6608'%3E%3Cpath d='M34,5.83A19.93,19.93,0,1,0,34,34,19.95,19.95,0,0,0,34,5.83ZM32.65,32.65a18,18,0,1,1,0-25.46A18,18,0,0,1,32.65,32.65Z' /%3E%3Cpath d='M15.26,20.81a1,1,0,0,0,0-1.78l-8.5-3.52A1.93,1.93,0,0,0,4.13,17a16.09,16.09,0,0,0,0,5.95,1.93,1.93,0,0,0,2.63,1.43Z' /%3E%3Cpath d='M7.49,13.73,16,17.26A1,1,0,0,0,17.26,16L13.73,7.49a1.93,1.93,0,0,0-2.87-.85,16.08,16.08,0,0,0-2.3,1.91,15.9,15.9,0,0,0-1.92,2.31A1.93,1.93,0,0,0,7.49,13.73Z' /%3E%3Cpath d='M19,15.26a1,1,0,0,0,1.78,0l3.53-8.51a1.94,1.94,0,0,0-1.43-2.63,16.19,16.19,0,0,0-6,0,1.93,1.93,0,0,0-1.43,2.63Z' /%3E%3Cpath d='M23.84,17.26l8.51-3.53a1.92,1.92,0,0,0,.85-2.87A15.88,15.88,0,0,0,29,6.64a1.93,1.93,0,0,0-2.87.85L22.59,16A1,1,0,0,0,23.84,17.26Z' /%3E%3Cpath d='M35.71,16.94a1.92,1.92,0,0,0-2.63-1.43L24.58,19a1,1,0,0,0,0,1.78l8.5,3.52a1.92,1.92,0,0,0,2.63-1.43,16,16,0,0,0,0-6Z' /%3E%3Cpath d='M32.35,26.11l-8.51-3.52a1,1,0,0,0-1.25,1.25l3.52,8.51A1.92,1.92,0,0,0,29,33.2,15.88,15.88,0,0,0,33.2,29,1.92,1.92,0,0,0,32.35,26.11Z' /%3E%3Cpath d='M20.81,24.58a1,1,0,0,0-1.78,0l-3.52,8.51a1.92,1.92,0,0,0,1.43,2.63,16.14,16.14,0,0,0,6,0,1.93,1.93,0,0,0,1.43-2.63Z' /%3E%3Cpath d='M16,22.59,7.49,26.11A1.93,1.93,0,0,0,6.64,29a15.88,15.88,0,0,0,4.22,4.22,1.93,1.93,0,0,0,2.87-.84l3.53-8.52A1,1,0,0,0,16,22.59Z' /%3E%3C/svg%3E");
            top: 283rem;
            right: 18.5rem;

            @media (min-width: rem-to-px($container-width)) {
                right: calc(50vw - #{$container-width / 2} + 18.5rem);
            }
        }

        &--lemon-3 {
            width: 39.84px;
            height: 39.84px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.84' height='39.84' viewBox='0 0 39.84 39.84' fill='%23ffdc00'%3E%3Cpath d='M37.79,8.3a5.26,5.26,0,0,0-1-5.29,4.57,4.57,0,0,0-3.32-1.25,7.41,7.41,0,0,0-2,.29A17.33,17.33,0,0,0,23.2,0,23.18,23.18,0,0,0,7,7c-7.12,7.13-8.94,17.24-5,24.49a5.25,5.25,0,0,0,1,5.3,4.53,4.53,0,0,0,3.3,1.25,7.56,7.56,0,0,0,2-.29,17.34,17.34,0,0,0,8.34,2.05,23.17,23.17,0,0,0,16.15-7C39.92,25.68,41.75,15.55,37.79,8.3ZM31.44,31.44a21.28,21.28,0,0,1-14.79,6.48A15.47,15.47,0,0,1,9.23,36.1l-.7-.38-.75.22a5.24,5.24,0,0,1-1.47.22,2.72,2.72,0,0,1-1.94-.69,3.42,3.42,0,0,1-.47-3.41l.21-.76-.37-.69a16.36,16.36,0,0,1-1.46-11A21.84,21.84,0,0,1,8.4,8.4,21.33,21.33,0,0,1,23.2,1.92a15.46,15.46,0,0,1,7.41,1.82l.69.37.76-.21a5.11,5.11,0,0,1,1.45-.22,2.75,2.75,0,0,1,2,.69,3.44,3.44,0,0,1,.47,3.41l-.21.75.38.69a16.44,16.44,0,0,1,1.45,11A21.91,21.91,0,0,1,31.44,31.44Z' /%3E%3Cpath d='M22.17,3.88A19.59,19.59,0,0,0,9.76,9.76,20.48,20.48,0,0,0,4.39,19a1,1,0,0,0,.75,1.2h0a1,1,0,0,0,1.11-.7,18.62,18.62,0,0,1,4.87-8.36A17.64,17.64,0,0,1,22.28,5.8a1,1,0,1,0-.11-1.92Z' /%3E%3C/svg%3E");

            top: 305rem;
            left: 40rem;

            @media (min-width: rem-to-px($container-width)) {
                left: calc(50vw - #{$container-width / 2} + 40rem);
            }
        }
    }
}
