.dealers {
    margin-top: 4.6rem;
    margin-bottom: 4.6rem;

    @include for-tablet-landscape-up {
        margin-top: 7.4rem;
        margin-bottom: 14.5rem;
    }
    text-align: center;

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__pretitle {
        @include pretitle();
        margin-bottom: 1.6rem;
    }

    &__title {
        @include heading-3();
        margin-bottom: 3.6rem;

        @include for-tablet-landscape-up {
            margin-bottom: 9.3rem;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include for-tablet-portrait-up {
            flex-wrap: nowrap;
        }

        margin-top: -3.6rem;
        margin-left: -2rem;

        .dealer-item {
            width: 100%;

            @include for-tablet-portrait-up {
                width: 50%;
                width: auto;
            }


            display: block;
            padding-top: 3.6rem;
            padding-left: 2rem;

            img {
                object-fit: contain;
                max-height: 8rem;
                margin: 0 auto;
            }
        }
    }
}
