.hero {
    position: relative;

    &__content {
        @include container(
            $gutterDefault: $gutter-mobile,
            $gutterBreakpoints: (
                "for-tablet-landscape-up": $gutter-landscape,
                "for-desktop-up": $gutter-desktop,
            ),
            $maxWidth: $container-width,
            $breakpoint: "for-tablet-landscape-up",
            $breakout: null
        );

        height: 100%;
        z-index: 1;
        position: relative;

        // Offsets
        padding-top: $header-height-mobile + 6.4rem;
        padding-bottom: 6.4rem;

        @include for-tablet-landscape-up {
            min-height: 75rem;
            padding-top: ($header-height-landscape + 6.4rem);
            padding-bottom: 6.4rem;
        }

        @include for-desktop-up {
            padding-top: ($header-height-desktop + 6.4rem);
            padding-bottom: 12rem;
        }

        &__inner {
            position: relative;
        }
    }

    &__pretitle {
        @include pretitle();
        margin-bottom: 2.5rem;
    }

    &__title {
        @include heading-1();

        br {
            display: none;
        }
        
        @include for-tablet-portrait-up {
            br {
                display: inline;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;

        picture {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__breadcrumb {
        position: absolute;
        left: 0;
        bottom: calc(100% + 4.5rem);

        .breadcrumb {
            color: white;
        }
    }

    &__body {
        @include for-tablet-landscape-up {
            display: flex;
            align-items: center;
        }

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    &__text {
        @include for-tablet-landscape-up {
            &:not(:only-child) {
                flex-grow: 1;
            }
        }
    }
    &__emblem {
        flex-shrink: 0;
        margin-top: 2rem;
        margin-bottom: 4rem;

        @include for-tablet-landscape-up {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 4rem;
        }
    }

    &__buttons {
        display: flex;

        > * + * {
            margin-left: 1.2rem;
        }
    }

    &__button {
        &-primary {
            @include button-yellow();
        }

        &-secondary {
            @include button-white();
        }
    }
}
