// Forms

.form__errors,
.ff-errors {
    display: none;
    // padding: 0;
    // margin: 0.5rem 0 0;

    // > li {
    //     color: red;
    // }
}

.ff-form-errors {
    padding: 1rem 1.5rem;
    border: 1px solid $color-red;
    background: lighten($color-red, 15%);
    border-radius: 5px;
    color: white;
    margin-bottom: 1.2rem;

    > p {
        margin: 0;
    }
}

.ff-form-success {
    padding: 1rem 1.5rem;
    border: 1px solid $color-yellow;
    background: lighten($color-yellow, 15%);
    border-radius: 5px;
    color: black;
    margin-bottom: 1.2rem;

    > p {
        margin: 0;
    }
}

.form {
    font-family: $font-roboto;
    font-weight: $font-weight-regular;
    font-size: 1.2rem;
    width: 100%;
}

.form__row {
    display: flex;
    justify-content: space-between;
    margin-left: -$form-gutter-x / 2;
    margin-right: -$form-gutter-x / 2;
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
        margin-top: (-$form-gutter-y) / 2;
    }
}

.form__column {
    flex: 1 0;
    padding: ($form-gutter-y / 2) 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $form-gutter-x / 2;
    margin-right: $form-gutter-x / 2;

    &--content-align-left {
        display: flex;
        justify-content: flex-start;

        > button:not(:first-of-type) {
            margin-left: 1rem;
        }
    }
    &--content-align-center {
        display: flex;
        justify-content: center;

        > button:not(:first-of-type) {
            margin-left: 1rem;
        }
    }
    &--content-align-right {
        display: flex;
        justify-content: flex-end;

        > button:not(:first-of-type) {
            margin-left: 1rem;
        }
    }
    &--content-align-spread {
        display: flex;
        justify-content: space-between;

        > button:not(:first-of-type) {
            margin-left: 1rem;
        }
    }
}

.form__label {
    display: block;
    color: $color-yellow;
    margin-bottom: 1rem;

    // &.is-required {
    //     &::after {
    //         content: "*";
    //         margin-left: 5px;
    //         color: red;
    //     }
    // }
}

.form__input {
    width: 100%;
    display: block;
    appearance: none;
    outline: none;
    border-radius: 0.5rem;
    border: 1px solid $color-gray-2;
    padding: 1.75rem 1.75rem;

    &:not([disabled]) {
        &:focus {
            border-color: $color-green;
        }
    }

    &.ff-has-errors {
        border-color: red;
    }
}

.form__checkbox {
    /* Customize the label (the container) */
    display: block;
    position: relative;
    padding-left: 2rem + 0.8rem;

    cursor: pointer;

    line-height: 2;
    user-select: none;

    /* Hide the browser's default radio button */
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0.1rem;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: transparent;
        border: 1px solid $color-yellow;
        border-radius: 100%;
        transition: border-color 0.2s ease-in-out;

        &::after {
            content: "";
            position: absolute;
            opacity: 0;
            top: 0.4rem;
            left: 0.4rem;
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            background: $color-yellow;
            transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
        }
    }

    .label {
        display: inline-block;
        color: $color-gray-3;
        transition: color 0.2s ease-in-out;
        // margin-top: 0.2rem;

        line-height: 1.57;

        a {
            text-decoration-color: currentColor;
            text-underline-position: auto;

            &:hover {
                text-decoration-color: transparent;
            }
        }
    }

    .ff-errors {
        display: none;
    }

    /* On mouse-over, add a grey background color */
    &:hover input[type="checkbox"] ~ .checkmark::after {
        opacity: 0.35;
    }

    /* Show the indicator (dot/circle) when checked */
    input[type="checkbox"]:checked ~ .checkmark {
        border-color: $color-yellow;
        &:after {
            opacity: 1;
            background-color: $color-yellow;
        }
    }

    input[type="checkbox"] {
        // &:focus ~ .checkmark {
        //     box-shadow: 0 0 0 0.2rem rgba($color-yellow, 0.16);
        // }
        &.ff-has-errors {
            ~ .checkmark {
                border-color: red;

                &::after {
                    background: red;
                }
            }

            ~ .label {
                color: red;
            }
        }
    }
}
.form__submit {
    @include button-yellow($show-hover-border: false);
}

.form__submit--border {
    @include button-yellow($show-hover-border: true);
}
