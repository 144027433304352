html {
    font-size: 62.5%;
}

body {
    font-family: $font-roboto;
    font-weight: $font-weight-regular;
    font-size: 1.6rem;
    line-height: 1;
    color: $color-gray-3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.nav-open {
        overflow: hidden;
        @include for-tablet-landscape-up {
            overflow: visible;
        }
    }
}

// Sticky footer
#wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    #content {
        flex: 1;
    }
}

// Address
address {
    font: inherit;
}

// Image related
picture {
    display: block;
}

img {
    display: block;
    width: auto;
    max-width: 100%;
}

a {
    color: inherit;
    transition: color 0.2s ease-in-out, text-decoration-color 0.2s ease-in-out;
    text-decoration-color: transparent;
    text-underline-position: under;
}

// Number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

input,
button,
optgroup,
select,
textarea {
    font: inherit;
}

// ::-moz-selection {
//     background: $color-primary;
//     color: $color-gray-dark;
// }
// ::selection {
//     background: $color-primary;
//     color: $color-gray-dark;
// }

// Helpers
.visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

// Helper functions
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function rem-to-px($rem) {
    @return #{strip-unit($rem) * 10}px;
}
