@mixin heading-base() {
    display: block;
    font-family: $font-gordita;
}

@mixin pretitle($color: $color-yellow) {
    @include heading-base();
    color: $color;
    font-weight: $font-weight-bold;
}

@mixin heading-1($color: white) {
    @include heading-base();
    color: $color;
    font-weight: $font-weight-bold;
    line-height: 1.14;
    font-size: (7rem / 1.75);
    hyphens: auto;
    // word-break: break-word;

    @include for-tablet-portrait-up {
        font-size: (7rem / 1.5);
        hyphens: manual;
    }

    @include for-tablet-landscape-up {
        font-size: 7rem;
    }
}

@mixin heading-2($color: black) {
    @include heading-base();
    color: $color;
    font-weight: $font-weight-bold;
    line-height: 1.14;
    font-size: (6rem / 1.75);
    hyphens: auto;
    // word-break: break-word;

    @include for-tablet-portrait-up {
        hyphens: manual;
    }
    @include for-tablet-landscape-up {
        font-size: (6rem / 1.5);
    }

    @include for-desktop-up {
        font-size: 6rem;
    }
}

@mixin heading-3($color: black) {
    @include heading-base();
    color: $color;
    font-weight: $font-weight-bold;
    line-height: 1.175;
    font-size: (4rem / 1.75);
    hyphens: auto;
    // word-break: break-word;

    @include for-tablet-portrait-up {
        hyphens: manual;
    }

    @include for-tablet-landscape-up {
        font-size: (4rem / 1.5);
    }

    @include for-desktop-up {
        font-size: 4rem;
    }
}

@mixin body-text($color: inherit, $font-size: 2rem, $line-height: 1.5) {
    ul,
    ol,
    p,
    h2,
    h3,
    h4 {
        margin: 1em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol,
    p {
        color: $color;
        font-size: $font-size;
        line-height: 1.5;
    }

    h2,
    h3,
    h4 {
        @include heading-base();
        font-weight: $font-weight-bold;
        color: black;
    }

    h2 {
        font-size: 2.4rem;
    }

    h3 {
        font-size: 2.1rem;
    }

    h4 {
        font-size: 1.8rem;
    }

    ul {
        li {
            padding-left: 1em;
            position: relative;
            &::before {
                content: "-";
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    ol {
        list-style-type: decimal;

        li {
            margin-left: 1em;
        }
    }

    a {
        text-decoration-color: currentColor;
        text-underline-position: auto;

        &:hover {
            text-decoration-color: transparent;
        }
    }
}
