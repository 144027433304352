.error-page {
    // min-height: 50vh;
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__inner {
        text-align: center;
    }

    &__title {
        @include heading-2($color: black);
        margin-bottom: 1rem;
    }

    &__text {
        @include body-text();
        margin-bottom: 2rem;
    }

    &__button {
        @include button-yellow($show-hover-border: true);
    }
}
