.header {
    $c: &;

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    & {
        position: fixed; // maybe sticky if possible with other content
        top: 0;
        left: 0;
        right: 0;
        z-index: $z-header;

        // Gradient background (color is given based on --light or --dark modifier)
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            z-index: -1;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $header-height-mobile;

        @include for-tablet-landscape-up {
            height: $header-height-landscape;
        }

        @include for-desktop-up {
            height: $header-height-desktop;
        }
    }

    &__brand {
        position: relative;
        z-index: 1;
        .logo {
            display: inline-block;
            svg {
                height: auto;
                width: 10rem;

                @include for-tablet-landscape-up {
                    width: 15rem;
                }

                @include for-desktop-up {
                    width: 20rem;
                }
            }
        }
    }

    &__nav {
        // display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $gutter-mobile;
        padding-top: $header-height-mobile;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
        background-color: $color-yellow;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @include for-tablet-landscape-up {
            position: static;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            padding: 0;

            flex-direction: row;
            justify-content: flex-start;

            align-items: center;
            margin-left: 3rem;

            opacity: 1;
            visibility: visible;
            background: transparent;
        }

        &-scroller {
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            @include for-tablet-landscape-up {
                align-items: center;
                flex-direction: row;
                overflow: visible;
            }
        }

        .main-nav {
            position: relative;
            text-align: center;

            &__list {
                @include for-tablet-landscape-up {
                    display: flex;
                    align-items: center;
                }
            }

            &__item {
                position: relative;

                > a {
                    display: block;
                    font-family: $font-gordita;
                    font-weight: $font-weight-bold;
                    text-transform: lowercase;
                    font-size: 1.6rem;
                    line-height: 1;
                    padding-top: 0.6rem;
                    padding-bottom: 0.6rem;

                    @include for-tablet-landscape-up {
                        &::before {
                            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.88' height='14.94' viewBox='0 0 29.88 14.94' fill='%23ffdc00'%3E%3Cpath d='M25.51,4.38A14.94,14.94,0,0,0,0,14.94H1.45a13.49,13.49,0,1,1,27,0h1.45A14.9,14.9,0,0,0,25.51,4.38Z' /%3E%3Cpath d='M17.17,3.1a12,12,0,0,0-4.46,0,1.44,1.44,0,0,0-1.08,2l2.64,6.38a.73.73,0,0,0,1.34,0l2.64-6.38A1.44,1.44,0,0,0,17.17,3.1Z' /%3E%3Cpath d='M24.9,8.14A12.11,12.11,0,0,0,21.73,5a1.44,1.44,0,0,0-2.15.64L16.94,12a.72.72,0,0,0,.94.94l6.38-2.64A1.45,1.45,0,0,0,24.9,8.14Z' /%3E%3Cpath d='M24.82,11.63l-6.39,2.64a.73.73,0,0,0-.44.67h9a12,12,0,0,0-.21-2.23A1.44,1.44,0,0,0,24.82,11.63Z' /%3E%3Cpath d='M11.45,14.27,5.06,11.63a1.44,1.44,0,0,0-2,1.07,12.05,12.05,0,0,0-.21,2.24h9A.73.73,0,0,0,11.45,14.27Z' /%3E%3Cpath d='M10.3,5.62A1.44,1.44,0,0,0,8.15,5,12.11,12.11,0,0,0,5,8.14a1.45,1.45,0,0,0,.63,2.16L12,12.94a.72.72,0,0,0,.94-.94Z' /%3E%3C/svg%3E");
                            position: absolute;
                            bottom: 100%;
                            left: 50%;

                            margin-bottom: 0.4rem;
                            opacity: 0;
                            transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
                            transform: translateX(-50%) translateY(50%);
                            pointer-events: none;
                            z-index: -1;
                        }
                    }
                }

                &:hover > a,
                &--active > a {
                    @include for-tablet-landscape-up {
                        &::before {
                            opacity: 1;
                            transform: translateX(-50%);
                        }
                    }
                }

                &:hover > a {
                    + .drink-nav {
                        @include for-tablet-landscape-up {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                > a:hover {
                    text-decoration-color: currentColor;
                    @include for-tablet-landscape-up {
                        text-decoration-color: transparent;
                    }
                }

                &:not(:first-child) {
                    margin-top: 2.4rem;

                    @include for-tablet-landscape-up {
                        margin-top: 0;
                        margin-left: 3.6rem;
                    }
                    @include for-desktop-up {
                        margin-left: 5.4rem;
                    }
                }
            }
        }

        .drink-nav {
            padding-top: 1.8rem;

            @include for-tablet-landscape-up {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                text-align: left;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
                color: white;
                z-index: 1;
            }

            &__item {
                line-height: 1.5;
                @include for-tablet-landscape-up {
                    position: relative;
                    line-height: 1;
                }
            }

            a:hover {
                text-decoration-color: currentColor;
                @include for-tablet-landscape-up {
                    text-decoration-color: transparent;
                }
            }

            .drink-nav-card {
                display: block;
                position: relative;
                padding-top: 0.6rem;
                padding-bottom: 0.6rem;

                @include for-tablet-landscape-up {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &__body {
                    @include for-tablet-landscape-up {
                        position: absolute;
                        left: 9rem;
                        right: 2rem;

                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &__title {
                    @include heading-base();
                    font-weight: $font-weight-bold;
                    font-size: 1.4rem;
                    color: black;
                    @include for-tablet-landscape-up {
                        font-size: 2rem;
                        margin-bottom: 1rem;
                    }
                }

                &__text {
                    display: none;
                    @include for-tablet-landscape-up {
                        display: block;
                        @include body-text($color: black, $font-size: 1.2rem, $line-height: 1.667);
                    }
                }

                &__image {
                    display: none;
                    @include for-tablet-landscape-up {
                        display: block;
                        width: 28.4rem;
                        height: 28.4rem;

                        picture {
                            height: 100%;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .lang-nav {
            position: relative;
            font-family: $font-gordita;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            font-size: 1.4rem;
            line-height: 1;
            margin-top: 2.4rem;

            @include for-tablet-landscape-up {
                margin-top: 0;
                margin-left: 3.6rem;
            }
            @include for-desktop-up {
                margin-left: 6.8rem;
            }

            &__list {
                justify-content: center;
                display: flex;

                @include for-tablet-landscape-up {
                    justify-content: flex-start;
                }
            }

            &__item {
                display: flex;
                &--active > a,
                &:hover > a {
                    text-decoration-color: currentColor;
                }

                &:not(:first-child) {
                    &::before {
                        content: "\00a0|\00a0";
                    }
                }
            }
        }
    }

    &__toggle {
        position: relative;
        display: block;
        width: 2.2rem;
        height: 1.6rem;
        margin-left: 2.4rem;
        cursor: pointer;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
        z-index: 3;
        align-self: center;

        &:focus {
            outline: none;
        }

        @include for-tablet-landscape-up {
            display: none;
        }

        span {
            display: block;
            position: absolute;
            height: 0.1rem;
            width: 100%;
            margin: 0;
            border-radius: 5px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.2s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 8px;
            }

            &:nth-child(4) {
                top: 16px;
            }
        }

        body.nav-open & {
            span {
                &:nth-child(1) {
                    top: 16px;
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 16px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }

    &--light {
        // Gradient bg
        &::before {
            background: linear-gradient(0deg, rgba(black, 0) 0%, rgba(black, 1) 100%);
        }
        // @include for-tablet-landscape-up {
        //     background: none;
        // }
        // backdrop-filter: blur(5px);

        #{$c} {
            &__toggle {
                span {
                    background: white;

                    body.nav-open & {
                        background: black;
                    }
                }
            }
        }

        .logo,
        .main-nav,
        .lang-nav {
            color: white;

            body.nav-open & {
                color: black;
                @include for-tablet-landscape-up {
                    color: white;
                }
            }
        }
    }

    &--dark {
        position: sticky;
        // Gradient background
        &::before {
            background: linear-gradient(0deg, rgba(white, 0) 0%, rgba(white, 1) 100%);
        }

        #{$c} {
            &__toggle {
                span {
                    background: black;

                    // body.nav-open & {
                    //     background: white;
                    // }
                }
            }
        }
        .logo {
            color: black;

            // body.nav-open & {
            //     color: white;
            // }
        }

        .main-nav,
        .lang-nav {
            color: black;
        }
    }

    &--scrolled {
        &::before {
            opacity: 1;
        }
    }

    body.nav-open & {
        #{$c} {
            &__nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
