@mixin button-base() {
    display: inline-flex;
    border: 1px solid transparent;
    text-decoration: none;
    outline: none;
    appearance: none;
    user-select: none;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
    font-family: $font-roboto;
    font-weight: $font-weight-bold;
    padding: 1.3rem 1.7rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    line-height: 1.14;
    color: black;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:not([disabled]) {
        cursor: pointer;
    }

    svg {
        display: inline-block;
        margin-top: -1.3rem;
        margin-bottom: -1.3rem;
        align-self: center;

        &:first-child {
            margin-right: 1rem;
        }

        &:last-child {
            margin-left: 1rem;
        }
    }
}

@mixin button-yellow($show-hover-border: false) {
    @include button-base();

    background-color: $color-yellow;
    border-color: $color-yellow;

    &:not([disabled]) {
        &:hover {
            background-color: white;

            @if $show-hover-border {
                border-color: $color-yellow;
            } @else {
                border-color: white;
            }
        }
    }
}

@mixin button-yellow-ghost() {
    @include button-base();

    background-color: transparent;
    border-color: $color-yellow;

    &:not([disabled]) {
        &:hover {
            background-color: $color-yellow;
        }
    }
}

@mixin button-white() {
    @include button-base();

    background-color: white;
    border-color: white;

    &:not([disabled]) {
        &:hover {
            background-color: $color-yellow;
            border-color: $color-yellow;
        }
    }
}
