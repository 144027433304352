.page--dealersOverview {
    .dealer {
        .dealer-intro {
            @include container(
                $gutterDefault: $gutter-mobile,
                $gutterBreakpoints: (
                    "for-tablet-landscape-up": $gutter-landscape,
                    "for-desktop-up": $gutter-desktop,
                ),
                $maxWidth: $container-width,
                $breakpoint: "for-tablet-landscape-up",
                $breakout: null
            );

            .breadcrumb {
                margin-bottom: 4.6rem;
            }

            &__pretitle {
                @include pretitle();
                margin-bottom: 2.5rem;
            }

            &__title {
                @include heading-1($color: black);
                margin-bottom: $gutter-mobile;

                @include for-tablet-landscape-up {
                    margin-bottom: 6.2rem;
                }

                br {
                    display: none;
                }

                @include for-tablet-portrait-up {
                    br {
                        display: inline;
                    }
                }
            }

            &__form {
                margin-bottom: $gutter-mobile * 2;

                @include for-tablet-portrait-up {
                    display: flex;
                }

                @include for-tablet-landscape-up {
                    margin-bottom: 6.2rem;
                }

                input {
                    border: none;
                    appearance: none;
                    outline: none;
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid $color-gray-1;
                    height: 4.3rem;
                    font-size: 1.2rem;
                    flex-grow: 1;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='20' viewBox='0 0 15 20'%3E%3Cpath id='path-1' d='M6.73,19.6C1.05,11.37,0,10.52,0,7.5a7.5,7.5,0,0,1,15,0c0,3-1.05,3.87-6.73,12.1A.94.94,0,0,1,6.73,19.6Zm.77-9A3.12,3.12,0,1,0,4.38,7.5,3.11,3.11,0,0,0,7.5,10.62Z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 1.5rem 2rem;
                    background-position: calc(100% - 1rem) center;
                    padding-right: 4rem;
                    width: 100%;

                    @include for-tablet-portrait-up {
                        max-width: 29.4rem;
                    }

                    &::placeholder {
                        color: $color-gray-3;
                    }
                }

                button {
                    flex-shrink: 0;
                    @include button-yellow($show-hover-border: true);
                    margin-top: 1.7rem;
                    width: 100%;

                    @include for-tablet-portrait-up {
                        width: auto;
                        margin-top: 0;
                        margin-left: 1.7rem;
                    }
                }

                .reset {
                    flex-shrink: 0;
                    @include button-yellow-ghost();
                    margin-top: 1.7rem;
                    width: 100%;

                    @include for-tablet-portrait-up {
                        width: auto;
                        margin-top: 0;
                        margin-left: 1.7rem;
                    }
                }
            }

            &__no-results {
                display: block;
                margin-top: 1.2rem;
                margin-bottom: 4.6rem;
                color: $color-red;
            }
        }

        .dealer-map {
            height: 75vw;
            @include for-tablet-landscape-up {
                height: 76rem;
            }

            .mapboxgl-canvas {
                outline: none;
            }

            .mapboxgl-popup {
                width: 250px;
                max-width: none impora !important;
                font-family: $font-roboto;
                font-size: 1.6rem;
                line-height: 1;
                font-weight: $font-weight-light;
                color: black;

                &-tip {
                    display: none;
                }

                &-content {
                    width: 100%;
                    padding: 0;
                    box-shadow: none;
                    border-radius: 0.5rem;
                    overflow: hidden;

                    &__title {
                        font-family: $font-gordita;
                        font-weight: $font-weight-bold;
                        margin-bottom: 0.5rem;
                    }

                    &__image {
                        img {
                            width: 100%;
                        }
                    }

                    &__body {
                        line-height: 1.25;
                        font-size: 1.2rem;
                        padding: 2rem;
                    }

                    &__link {
                        @include button-yellow($show-hover-border: true);
                        font-size: 1.1rem;
                        padding: (1.3rem / 1.25) (1.7rem / 1.25);
                        margin-top: 0.8rem;

                        // display: inline-block;
                        // outline: none;
                        // margin-top: 0.4rem;
                        // text-decoration: underline;
                        // text-underline-position: under;
                    }
                }
            }

            .marker {
                display: block;
                // width: 4rem;
                // height: 5.8rem;
                width: 2rem;
                height: 2.9rem;

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.6' height='58' viewBox='0 0 40.6 58' fill='%23143925'%3E%3Cpath d='M20.3,0A20.29,20.29,0,0,0,0,20.3C0,35.52,20.3,58,20.3,58S40.6,35.52,40.6,20.3A20.29,20.29,0,0,0,20.3,0Zm0,27.55a7.25,7.25,0,1,1,7.25-7.25,7.26,7.26,0,0,1-7.25,7.25Z' /%3E%3C/svg%3E");
                // background-size: 4rem 5.8rem;
                background-size: 2rem 2.9rem;
                background-repeat: no-repeat;
            }
        }
    }
}
