.usps {
    margin-top: 4.6rem;
    margin-bottom: 4.6rem;
    
    @include for-tablet-landscape-up {
        margin-top: 10rem;
        margin-bottom: 12.6rem;
    }

    @include container(
        $gutterDefault: $gutter-mobile,
        $gutterBreakpoints: (
            "for-tablet-landscape-up": $gutter-landscape,
            "for-desktop-up": $gutter-desktop,
        ),
        $maxWidth: $container-width,
        $breakpoint: "for-tablet-landscape-up",
        $breakout: null
    );

    &__inner {
        display: grid;
        grid-template: auto / 1fr;
        grid-gap: 2.4rem;

        @include for-tablet-landscape-up {
            grid-gap: 7.4rem;
            grid-template: auto / repeat(2, 1fr);
        }

        @include for-desktop-up {
            grid-template: auto / repeat(4, 1fr);
        }
    }

    .usp-card {
        &__icon {
            height: 5rem;
            margin-bottom: 1rem;
            max-width: 5rem;
        }

        &__title {
            @include heading-base();
            font-weight: $font-weight-bold;
            font-size: 1.6rem;
            line-height: 1.526;
            color: black;
            margin-bottom: 1.6rem;

            @include for-tablet-portrait-up {
                min-height: 5.1rem; //
            }
        }

        &__text {
            @include body-text($font-size: 1.2rem, $line-height: 1.667);

            @include for-tablet-landscape-up {
                max-width: 80%;
            }
            @include for-desktop-up {
                max-width: none;
            }
        }
    }
}
